<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <date-time-picker
              @getDateFrom="dateFrom = $event"
              @getDateTo="dateTo = $event"
            >
            </date-time-picker>

            <v-row
              v-if="!$checkRole('ADVERTISERS') && !$checkRole('ADVERTISERS_USERS') && !$checkRole('PROVIDERS')"
            >
              <v-col
                cols="6"
                xl="6"
                md="6"
                sm="12"
              >
                <v-autocomplete
                  v-model="advertiserId"
                  :items="advertisers"
                  item-text="name"
                  item-value="id"
                  :label="$t('Advertiser')"
                  :disabled="loadingFirst"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="1"
                xl="1"
                md="1"
                sm="1"
              >
                <v-progress-circular
                  v-if="loadingFirst"
                  class="mt-5"
                  indeterminate
                  color="primary"
                  :width="2"
                  :size="20"
                ></v-progress-circular>
              </v-col>
            </v-row>

            <v-row
              v-if="$checkRole('PROVIDERS')"
            >
              <v-col
                cols="6"
                xl="6"
                md="6"
                sm="12"
              >
                <v-autocomplete
                  v-model="providersId"
                  :items="providers"
                  item-text="name"
                  item-value="id"
                  :label="$t('Provider')"
                  :disabled="loadingFirst"
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="1"
                xl="1"
                md="1"
                sm="1"
              >
                <v-progress-circular
                  v-if="loadingFirst"
                  class="mt-5"
                  indeterminate
                  color="primary"
                  :width="2"
                  :size="20"
                ></v-progress-circular>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="6"
                xl="6"
                md="6"
                sm="12"
              >
                <v-autocomplete
                  v-model="selectedStatuses"
                  :items="campaignStatuses[$i18n.locale]"
                  item-text="name"
                  item-value="key"
                  :label="$t('Status')"
                  multiple
                  clearable
                  hide-details
                  :menu-props="{contentClass:'list-style'}"
                >
                  <template #selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      label
                      :input-value="data.selected"
                      close
                      @click:close="selectedStatuses = handleRemoveFromArray(selectedStatuses, data.item.key)"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                v-if="!$checkRole('PROVIDERS')"
                cols="6"
                xl="6"
                md="6"
                sm="12"
              >
                <v-autocomplete
                  v-model="selectedTypes"
                  :items="campaignTypes[$i18n.locale]"
                  item-text="name"
                  item-value="key"
                  :label="$t('CampaingType')"
                  multiple
                  clearable
                  hide-details
                  :menu-props="{contentClass:'list-style'}"
                >
                  <template #selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      label
                      :input-value="data.selected"
                      close
                      @click:close="selectedTypes = handleRemoveFromArray(selectedTypes, data.item.key)"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                v-if="!$checkRole('PROVIDERS')"
                cols="6"
                xl="6"
                md="6"
                sm="12"
              >
                <v-autocomplete
                  v-model="selectedLocations"
                  :items="campaignLocations[$i18n.locale]"
                  item-text="name"
                  item-value="key"
                  :label="$t('Location')"
                  multiple
                  clearable
                  hide-details
                  :menu-props="{contentClass:'list-style'}"
                >
                  <template #selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      label
                      :input-value="data.selected"
                      close
                      @click:close="selectedLocations = handleRemoveFromArray(selectedLocations, data.item.key)"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-btn
              class="mt-5"
              color="primary"
              @click="getData"
            >
              {{ $t('BtnSearch') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-row v-if="!$checkRole('PROVIDERS')">
      <v-col>
        <v-card class="w-full">
          <v-card-text>
            <v-tabs vertical>
              <v-tab>
                {{ $t('Activity') }}
              </v-tab>
              <v-tab>
                {{ $t('Total') }}
              </v-tab>
              <v-tab>
                {{ $t('Geo') }}
              </v-tab>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <activity-widget
                      :date-from="transferDateFrom"
                      :date-to="transferDateTo"
                      :advertiser-id="transferAdvertiserId"
                      :locations="transferLocations"
                      :campaing-types="transferCampaingTypes"
                      :statuses="transferStatuses"
                    ></activity-widget>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <total-widget
                      :date-from="transferDateFrom"
                      :date-to="transferDateTo"
                      :advertiser-id="transferAdvertiserId"
                      :locations="transferLocations"
                      :campaing-types="transferCampaingTypes"
                      :statuses="transferStatuses"
                    ></total-widget>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <geo-widget
                  :date-from="transferDateFrom"
                  :date-to="transferDateTo"
                  :advertiser-id="transferAdvertiserId"
                  :locations="transferLocations"
                  :campaing-types="transferCampaingTypes"
                  :statuses="transferStatuses"
                ></geo-widget>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col>
        <v-data-table
          v-model="selectedCampaigns"
          :headers="headers"
          :items="dataFromDb"
          item-key="campaign.id"
          class="elevation-1"
          :sort-by.sync="orderBy"
          :sort-desc.sync="sortDesc"
          disable-sort
          :search="search"
          :loading="loading"
          :locale="$i18n.locale"
        >
          <template v-slot:top>
            <div class="row">
              <div class="col-8">
                <div class="container">
                  <v-btn
                    outlined
                    color="primary"
                    @click="exportToExcel()"
                  >
                    {{ $t('VtrReportElements.Download') }}
                  </v-btn>
                </div>
              </div>
              <div class="col-4">
                <v-text-field
                  v-model="search"
                  :label="$t('LblSearch')"
                  class="mx-4"
                ></v-text-field>
              </div>
            </div>
          </template>

          <template #[`item.campaignName`]="{item}">
            <v-row>
              <v-col>
                <router-link
                  :to="{name: 'campaigns-statistics', params: { campaign_id: item.campaign.id } }"
                  class="d-flex align-center text-decoration-none"
                >
                  {{ item.campaignName }}
                </router-link>
              </v-col>
            </v-row>
          </template>

          <template #[`item.startDate`]="{item}">
            <v-row>
              <v-col>
                {{ item.startDate.substring(0, 10) }}
              </v-col>
            </v-row>
          </template>

          <template #[`item.finishDate`]="{item}">
            <v-row>
              <v-col>
                {{ item.finishDate.substring(0, 10) }}
              </v-col>
            </v-row>
          </template>

          <template #[`item.status`]="{item}">
            <v-row>
              <v-col>
                <v-chip
                  v-show="item.status === 'active'"
                  color="success"
                >
                  {{ $t('Campaign.Status.Active') }}
                </v-chip>
                <v-chip
                  v-show="item.status === 'suspended'"
                  color="warning"
                >
                  {{ $t('Campaign.Status.Paused') }}
                </v-chip>
                <v-chip
                  v-show="item.status === 'finished'"
                  color="error"
                >
                  {{ $t('Campaign.Status.Inactive') }}
                </v-chip>
              </v-col>
            </v-row>
          </template>

          <template #[`item.reports`]="{item}">
            <v-row>
              <v-col>
                <v-menu
                  top
                  offset-x
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <template
                      v-for="(items, index) in menuItems"
                    >
                      <v-list-item
                        v-if="$checkAbility(items.resource)"
                        :key="index"
                        :to="{name: items.to, params: { campaign_id: item.campaign.id } }"
                      >
                        <v-list-item-icon class="mr-3">
                          <v-icon v-text="items.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ items.title }}</v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiChartBar, mdiEarth, mdiCardAccountDetails, mdiStar, mdiChartLine } from '@mdi/js'
import qs from 'qs'
import * as XLSX from 'xlsx-js-style'

import TotalWidget from './Widgets/TotalWidget.vue'
import DateTimePicker from '../Components/DateTimePicker.vue'

import GeoWidget from './Widgets/GeoWidget.vue'
import ActivityWidget from './Widgets/ActivityWidget.vue'

export default {
  components: {
    TotalWidget,
    DateTimePicker,

    GeoWidget,
    ActivityWidget,
  },
  data() {
    return {
      orderBy: 'id',
      sortDesc: true,
      search: '',
      advertisers: [],
      providers: [],
      showInactive: false,
      dataFromDb: [],
      headers: [],
      selectedCampaigns: [],
      providersId: 0,
      advertiserId: Number(this.$route.params.advertiser_id),
      dateFrom: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      )
        .toISOString()
        .substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      transferDateFrom: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      )
        .toISOString()
        .substr(0, 10),
      transferDateTo: new Date().toISOString().substr(0, 10),
      loading: false,
      loadingFirst: false,
      icons: {
        mdiDotsVertical,
      },

      transferLocations: ['desktop', 'mobile'],
      transferStatuses: ['active', 'suspended'],
      transferCampaingTypes: ['video', 'banner', 'native'],
      transferAdvertiserId: null,

      selectedStatuses: ['active', 'suspended'],
      campaignStatuses: {
        en: [
          {
            name: 'Active',
            key: 'active',
          },
          {
            name: 'Paused',
            key: 'suspended',
          },
          {
            name: 'Inactive',
            key: 'finished',
          },
        ],
        ru: [
          {
            name: 'Активна',
            key: 'active',
          },
          {
            name: 'Приостановлена',
            key: 'suspended',
          },
          {
            name: 'Завершена',
            key: 'finished',
          },
        ],
      },
      selectedTypes: ['video', 'banner', 'native'],
      campaignTypes: {
        en: [
          {
            name: 'Video',
            key: 'video',
          },
          {
            name: 'Banners',
            key: 'banner',
          },
          {
            name: 'Native',
            key: 'native',
          },
        ],
        ru: [
          {
            name: 'Видео',
            key: 'video',
          },
          {
            name: 'Баннеры',
            key: 'banner',
          },
          {
            name: 'Нативная',
            key: 'native',
          },
        ],
      },
      selectedLocations: ['desktop', 'mobile'],
      campaignLocations: {
        en: [
          {
            name: 'Desktop',
            key: 'desktop',
          },
          {
            name: 'Mobile',
            key: 'mobile',
          },
        ],
        ru: [
          {
            name: 'Десктоп',
            key: 'desktop',
          },
          {
            name: 'Мобайл',
            key: 'mobile',
          },
        ],
      },
      menuItems: [
        {
          title: this.$t('Statistics'),
          to: 'campaigns-statistics',
          icon: mdiChartBar,
          resource: 'REPORT_CAMPAIGN_STATISTICS',
        },
        {
          title: this.$t('CampaignGeo'),
          to: 'campaigns-geo',
          icon: mdiEarth,
          resource: 'REPORT_CITY',
        },
        {
          title: this.$t('CampaignAuditory'),
          to: 'campaigns-auditory',
          icon: mdiCardAccountDetails,
          resource: 'REPORT_AUDIENCE_DEMOGRAPHY',
        },
        {
          title: this.$t('CampaingCreatives'),
          to: 'campaigns-creatives',
          icon: mdiStar,
          resource: 'REPORT_CREATIVES',
        },
        {
          title: this.$t('CampaingProvidersReport'),
          to: 'campaigns-providers',
          icon: mdiChartLine,
          resource: 'REPORT_CREATIVES',
        },
      ],
    }
  },
  watch: {
    selectedCampaigns() {
      // watch it
    },
  },
  mounted() {
    this.firstLoad()

    // this.$root.$on('update_city', this.getData())
  },
  methods: {
    exportToExcel() {
      const wb = XLSX.utils.book_new()
      const rows = []

      const sl = XLSX.utils.json_to_sheet(rows)

      let viewabilityCol = 'H'
      let fraudCol = 'I'
      let coastCol = 'J'
      let startDateCol = 'K'
      let finishDateCol = 'L'
      let marginlenght = 11

      if (!this.$checkAbility('CAN_VIEW_COST') && this.$checkAbility('CAN_VIEW_FRAUD_AND_VIEWABLITY')) {
        viewabilityCol = 'H'
        fraudCol = 'I'
        startDateCol = 'J'
        finishDateCol = 'K'
        marginlenght = 10
      }
      if (!this.$checkAbility('CAN_VIEW_COST') && !this.$checkAbility('CAN_VIEW_FRAUD_AND_VIEWABLITY')) {
        startDateCol = 'H'
        finishDateCol = 'I'
        marginlenght = 8
      }
      if (this.$checkAbility('CAN_VIEW_COST') && !this.$checkAbility('CAN_VIEW_FRAUD_AND_VIEWABLITY')) {
        coastCol = 'H'
        startDateCol = 'I'
        finishDateCol = 'J'
        marginlenght = 9
      }

      // Рабочая область
      sl['!ref'] = 'A1:Z50'

      // соединение ячеек
      sl['!merges'] = [{ s: { r: 0, c: marginlenght }, e: { r: 0, c: 0 } }]

      // Ширина столбцов
      sl['!cols'] = [
        { width: 6 },
        { width: 50 },
        { width: 12 },
        { width: 12 },
        { width: 12 },
        { width: 12 },
        { width: 12 },
        { width: 12 },
        { width: 12 },
        { width: 12 },
        { width: 12 },
        { width: 12 },
      ]

      // верхняя шапка
      sl.A1 = {
        t: 's',
        v: `${this.$t('CampaignListExcelHeader')}`,
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, alignment: { horizontal: 'center' } },
      }

      // шапка таблицы
      sl.A2 = {
        t: 's',
        v: 'ID',
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
      }
      sl.B2 = {
        t: 's',
        v: this.$t('Name'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
      }
      sl.C2 = {
        t: 's',
        v: this.$t('Shows'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
      }
      sl.D2 = {
        t: 's',
        v: this.$t('References'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
      }
      sl.E2 = {
        t: 's',
        v: this.$t('Views'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
      }
      sl.F2 = {
        t: 's',
        v: this.$t('Сoverage'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
      }
      sl.G2 = {
        t: 's',
        v: this.$t('CTR'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
      }
      if (this.$checkAbility('CAN_VIEW_COST')) {
        sl[`${coastCol}2`] = {
          t: 's',
          v: this.$t('Coast'),
          s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
        }
      }
      if (this.$checkAbility('CAN_VIEW_FRAUD_AND_VIEWABLITY')) {
        sl[`${viewabilityCol}2`] = {
          t: 's',
          v: this.$t('Viewability'),
          s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
        }
        sl[`${fraudCol}2`] = {
          t: 's',
          v: this.$t('Fraud'),
          s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
        }
      }
      sl[`${startDateCol}2`] = {
        t: 's',
        v: this.$t('Start'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
      }
      sl[`${finishDateCol}2`] = {
        t: 's',
        v: this.$t('End'),
        s: { fill: { fgColor: { rgb: 'E9E9E9' } }, font: { bold: true } },
      }

      // Заполняем таблицу через AoA

      let count = 3
      this.dataFromDb.forEach(element => {
        sl[`A${count}`] = {
          t: 'n',
          v: element.campaign.id,
        }
        sl[`B${count}`] = {
          t: 's',
          v: element.campaignName,
        }
        sl[`C${count}`] = {
          t: 'n',
          v: element.views,
        }
        sl[`D${count}`] = {
          t: 'n',
          v: element.clicks,
        }
        sl[`E${count}`] = {
          t: 'n',
          v: element.shows,
        }
        sl[`F${count}`] = {
          t: 'n',
          v: element.uniqViews,
        }
        sl[`G${count}`] = {
          t: 'n',
          v: element.ctr,
        }
        if (this.$checkAbility('CAN_VIEW_COST')) {
          sl[`${coastCol}${count}`] = {
            t: 'n',
            v: element.cost,
          }
        }
        if (this.$checkAbility('CAN_VIEW_FRAUD_AND_VIEWABLITY')) {
          sl[`${viewabilityCol}${count}`] = {
            t: 'n',
            v: element.viewability,
          }
          sl[`${fraudCol}${count}`] = {
            t: 'n',
            v: element.fraud,
          }
        }
        sl[`${startDateCol}${count}`] = {
          t: 'd',
          v: element.startDate.substring(0, 10),
        }
        sl[`${finishDateCol}${count}`] = {
          t: 'd',
          v: element.finishDate.substring(0, 10),
        }
        // eslint-disable-next-line no-plusplus
        count++
      })

      // Добавляем к документу
      XLSX.utils.book_append_sheet(wb, sl, 'Sheet1')

      XLSX.writeFile(wb, `${this.$t('CampaignListExcelHeader')}.xlsx`)
    },
    firstLoad() {
      this.getAdvertisersOrProviders().then(() => {
        this.getData()
      })
    },
    getData() {
      this.loading = true
      this.transferDateFrom = this.dateFrom
      this.transferDateTo = this.dateTo
      this.transferLocations = this.selectedLocations
      this.transferCampaingTypes = this.selectedTypes
      this.transferStatuses = this.selectedStatuses
      this.transferAdvertiserId = this.advertiserId
      const dateStart = this.dateFrom != null ? `${this.dateFrom}` : '1970-01-01'
      // eslint-disable-next-line operator-linebreak
      const dateFinish = this.dateTo != null ? `${this.dateTo}` : `${new Date().toISOString().substr(0, 10)}`

      let params = {}
      if (!this.$checkRole('PROVIDERS')) {
        params = {
          params: {
            dateFrom: dateStart,
            dateTo: dateFinish,
            advertiserId: this.advertiserId,
            statuses: this.selectedStatuses,
            locations: this.selectedLocations,
            isProvider: false,
            campaingTypes: this.selectedTypes,
          },
          paramsSerializer: param => qs.stringify(param, { arrayFormat: 'repeat' }),
        }
      } else {
        params = {
          params: {
            dateFrom: dateStart,
            dateTo: dateFinish,
            providerIds: this.providersId,
            isProvider: true,
            statuses: this.selectedStatuses,
          },
          paramsSerializer: param => qs.stringify(param, { arrayFormat: 'repeat' }),
        }
      }

      // this.$http.get('/campaigns/table_data', params).then(response => {
      this.$http.get(`${this.$apiBaseURL}/campaigns`, params).then(response => {
        this.dataFromDb = response.data != null ? response.data : []
        this.headers = []

        this.headers.push({ text: 'ID', value: 'campaign.id' })
        this.headers.push({ text: this.$t('Name'), value: 'campaignName' })
        this.headers.push({ text: this.$t('Shows'), value: 'views' })
        this.headers.push({ text: this.$t('References'), value: 'clicks' })
        this.headers.push({ text: this.$t('Views'), value: 'shows' })
        this.headers.push({ text: this.$t('Сoverage'), value: 'uniqViews' })
        this.headers.push({ text: this.$t('CTR'), value: 'ctr' })

        if (this.$checkAbility('CAN_VIEW_COST')) {
          this.headers.push({ text: this.$t('Coast'), value: 'cost' })
        }

        if (this.$checkAbility('CAN_VIEW_FRAUD_AND_VIEWABLITY')) {
          this.headers.push({ text: this.$t('Viewability'), value: 'viewability' })
          this.headers.push({ text: this.$t('Fraud'), value: 'fraud' })
        }

        this.headers.push({ text: this.$t('Start'), value: 'startDate' })
        this.headers.push({ text: this.$t('End'), value: 'finishDate' })
        this.headers.push({ text: this.$t('Status'), value: 'status' })
        this.headers.push({ text: this.$t('Reports'), value: 'reports' })

        this.loading = false
      })
    },
    handleRemoveFromArray(array, element) {
      return array.filter(ele => ele !== element)
    },
    getAdvertisersOrProviders() {
      this.loadingFirst = true

      if (!this.$checkRole('PROVIDERS')) {
        return this.$http
          .get(`${this.$apiBaseURL}/publisher/filter/adv-users`, { params: { userId: this.$getUserId() } })
          .then(response => {
            this.advertisers = response.data
            if (!this.advertiserId) {
              if (this.advertisers.length > 0) {
                this.advertiserId = this.advertisers[0].id
              }
            }

            this.loadingFirst = false
          })
      }

      return this.$http
        .get(`${this.$apiBaseURL}/campaigns/filter/providers`, { params: { userId: this.$getUserId() } })
        .then(response => {
          this.providers = response.data

          if (this.providers !== null) {
            if (this.providers.length > 0) {
              this.providersId = this.providers[0].id
            } else {
              this.providersId = null
            }
          } else {
            this.providersId = null
          }

          this.loadingFirst = false
        })
    },
  },
}
</script>
